<template>

  <div id="con-cusers-app"  class="container-fluid ">

    <div class="row crm-orders-row">
      <div class="col-auto col-tree pt-4  mb-auto" style="height:100%">

       <!--           <li class="mb-3">
                 <a><i class="fas fa-angle-down" ></i>{{ $t('admin.sprava-uzivatelu') }}</a>
                 <ul>
                   <li v-bind:class="current_level==0 ? 'active':''"><a @click="setLvlFilter(0)"><i class="fas fa-angle-right"></i> {{ $t('admin.cekajici-u')}}</a></li>

                   <li v-bind:class="current_level==1 ? 'active':''" ><a @click="setLvlFilter(1)"><i class="fas fa-angle-right"></i> {{ $t('admin.overeny-zak') }}</a></li>

                   <li v-bind:class="current_level==2 ? 'active':''" ><a @click="setLvlFilter(2)"><i class="fas fa-angle-right"></i> {{ $t('admin.premiove-overeni') }}</a></li>

                 </ul>
               </li>
     -->
<div class="nav flex-column nav-pills">



              <a href='#' v-bind:class="mikroblog_level==0 ? 'nav-link active':'nav-link'"><a @click="showUnconfirmedMikroblok"> {{ $t('b2bein.admin.cekajici-u') }}</a></a>
              <a href='#'  v-bind:class="mikroblog_level==10 ? 'nav-link active':'nav-link'" ><a @click="showDeniedMikroblok"> {{ $t('b2bein.admin.zamitnute') }}</a></a>
            </div>


      </div>


      <div class="col-md-7 pt-3">
        <table class="table table-striped" v-if="current_level<10">
          <tr v-for="u in users" :key="u.id">
            <td><img v-bind:src="u.logo" class="logo" style="max-height: 50px"> </td>
            <td>{{u.company}}</td>
            <td>
              <star-rating v-bind:increment="1"
                           v-bind:max-rating="3"
                           v-bind:show-rating='false'
                           :read-only="true"
                           inactive-color="#cccccc"
                           active-color="#fddd00"
                           v-model="u.level"
                           v-bind:star-size="30">
              </star-rating>
            </td>
            <td>

              <button @click="modifyLevel(u,1)" class="btn btn-outlok">+ {{ $t('b2bein.admin.pridat') }}</button>
              <button @click="modifyLevel(u,-1)" class="btn btn-outlok">- {{ $t('b2bein.admin.odebrat') }}</button>
            </td>

          </tr>
        </table>



        <div v-if="mikroblog_level<100" style="max-width: 500px;">

          <select v-model="typPostu" @change="refreshMikroblog" class="mv-1">
            <option value="0">{{ $t('b2bein.Příspěvky') }} </option>
            <option value="1">{{ $t('b2bein.crm.poptavky') }}</option>
          </select>


          <div class="col-4book pt-4" id="col4book">
          <Mikroblog v-bind:current_user="current_user"
                         v-bind:posts="posts"
                         v-bind:enable_editor="false"
                         :konfirmator="true"
                         :show_navigator="false"
                         v-bind:partners="partners" ref="mikroblog1" :itemtype="0"></Mikroblog>

          </div>

        </div>


      </div>
    </div>
  </div>

</template>

<script>



import axios from "axios";
import  StarRating  from 'vue-star-rating'
import Mikroblog from "../mikroblog/mikroblog";

export default {
    name: "Confirmation",

    data(){
      return  {
        locale:'cs',
        current_level:1,
        mikroblog_level:100,
        users:[],
        posts: [],
        current_user: -1 , //window.current_user,
        partners: [] , //window.friends.friends,

        typPostu:0
      }
    },
    components: {
      StarRating, Mikroblog
    },
    watch: {
    },
    methods: {
      _load: function(level){
        axios.get(process.env.VUE_APP_API_ROOT_URL+'/admin/confirmation/users', { params:{ level: level}  }).then(response => {
          this.users = response.data.users

        })
      },
      setLvlFilter:function(level){
        this.mikroblog_level=111
        console.log('prd', level)
        this.current_level = level
        this._load(level)
      },
      modifyLevel:function(u, value){
        axios.get(process.env.VUE_APP_API_ROOT_URL+'/admin/confirmation/updatelevel',
            { params:{ level: this.current_level, user_id: u.id, val: value}  }
            ).then(response => {
          this.users = response.data.users
        })
      },

      showUnconfirmedMikroblok:function(){
        this.mikroblog_level=0
        this.current_level=100
        this.loadMikroblog()
      },

      showDeniedMikroblok:function(){
        this.mikroblog_level=10
        this.current_level=100
        this.loadMikroblog()
      },
      loadMikroblog:function(){
        //console.log('ko me', this.me.id)
        this.$nextTick(function() {

          //this.$refs.mikroblog1.item_type = this.typPostu
          this.$refs.mikroblog1.reloadByUrl('/admin/confirmation/load-mikroblogs', { level: this.mikroblog_level, t: new Date().getTime()})
        })

      },
      refreshMikroblog:function(){
        console.log('refresh mikrobl')
        this.loadMikroblog()
      }


    },
    mounted(){


      //this._load(0)

      this.showUnconfirmedMikroblok()

    },

  computed: {
    me() {
      if (this.$store.getters.me) {
        return this.$store.getters.me;
      } else
        return {}
    }

  }

  }






</script>

<style scoped lang="scss">
.nav a.nav-link {
    color:black;
    &.active{ color:white}

}
</style>
